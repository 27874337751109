const links = [
  {
    label: 'Github',
    url: 'https://github.com/lhuria94'
  },
  {
    label: 'Twitter',
    url: 'https://twitter.com/lovehuria'
  },
  {
    label: 'Instagram',
    url: 'https://www.instagram.com/love.huria/'
  }
]

export default links
