const links = [
  {
    label: 'Home',
    url: '/'
  },
  {
    label: 'About me',
    url: '/about/'
  }
]

export default links
